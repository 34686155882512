import React from 'react'
import Seo from "@components/seo"
import Layout from "@layout"
import Header from "@layout/header/header-one"
import Footer from "@layout/footer/footer-one"
import AboutArea from "@containers/index-processing/about-area"
import ContactArea from "@containers/index-infotechno/contact-area"
import slide1 from "@assets/images/interior1.jpg"
import PageHeader from "@components/pageheader";
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl"

const TecknicalAdvice = ({location,pageContext}) => {

    const intl = useIntl()
    const serviceTitles = intl.formatMessage({
      id: "technical_advice_request",
    })
    const content = [
      {
        image: slide1,
      }
      
    ]
  
    const srviceTexts = [
      {
        text: <FormattedMessage id="technical_advice" />,
     
      },
      {
        text: <FormattedMessage id="technical_follow_up_to_the _mplementation" />,
      },
      {
        text: <FormattedMessage id="training_courses_for_newly_graduated_engineers" />,
      },
      
     
    ]
    return ( 
        <Layout location={location}>
        <Seo title="Processing" />
        <Header />
        <main className="site-wrapper-reveal">
        <PageHeader
			pageContext={pageContext}
			location={location}
			title="IT Services"
      pageTitle="technical_advice_request"
		/>
          <AboutArea
            content={content}
            srviceTexts={srviceTexts}
           serviceTitles={serviceTitles}
          />
  
          <ContactArea />
        </main>
        <Footer />
      </Layout> 
     );
}
 
export default TecknicalAdvice;